<template>
  <v-dialog
    :value="isOpen"
    :maxWidth="maxWidth"
    :persistent="persistent"
    @click:outside="closed"
  >
    <v-card>
      <v-card-title>
        <slot name="title" />
      </v-card-title>
      <v-card-text>
        <slot name="content" />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <slot name="footer" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalTemplate',
  props: {
    maxWidth: {
      type: String,
      default: '800px'
    },
    isOpen: Boolean,
    persistent: Boolean
  },
  methods: {
    closed() {
      this.$emit('closed')
    }
  }
}
</script>

<style scoped>

</style>
