<template>
  <v-dialog
    :value="value"
    persistent
    :max-width="maxWidth"
    @input="toggleVisibility"
  >
    <v-card>
      <v-card-title>
        <slot name="title"/>
      </v-card-title>
      <v-card-text>
        <slot name="content">
          <div class="text-center">
            <h2>Jesteś pewien?</h2>
            <h3
              v-if="text"
              class="mt-3"
            >
              {{ text }}
            </h3>
          </div>
        </slot>
        <slot name="buttons">
          <div class="d-flex justify-space-around w-100 mt-4">
            <v-btn
              color="success"
              :loading="loading"
              @click="$emit('confirm')"
            >
              Tak
            </v-btn>
            <v-btn
              color="error"
              @click="$emit('deny')"
            >
              Nie
            </v-btn>
          </div>
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ConfirmationDialog',
    props: {
      value: Boolean,
      loading: Boolean,
      maxWidth: {
        type: String,
        default: '400px'
      },
      text: String
    },
    methods: {
      toggleVisibility() {
        this.$emit('input', !this.value)
      }
    }
  }
</script>

<style scoped>

</style>
